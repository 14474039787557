import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { TextRegular } from '../../../Text/TextRegular'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import moment from 'moment'
import { useToggle } from '../../../../hooks/useToggle'
import { useSelector } from 'react-redux'
import { currentClubDuck } from '../../../../ducks/currentClub.duck'

export interface LicenseFeeCreatorProps {
  onSaveDate: (date: string) => void
}

export const LicenseFeeCreator: FC<LicenseFeeCreatorProps> = ({
  onSaveDate
}) => {
  const [editMode, open, close] = useToggle()
  const [date, setDate] = useState<string>(moment().format('YYYY'))
  const currentClub = useSelector(currentClubDuck.selectors.data)
  const changeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value)
  }, [])
  const handleSave = useCallback(() => {
    onSaveDate(date)
    close()
  }, [onSaveDate, date, close])

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" gap={2}>
        <div>
          <TextRegular>
            Posiadasz licencję sportową <strong>w tym klubie?</strong>
          </TextRegular>
          <br />
          <TextRegular size="small">
            Jeśli posiadasz licencję sportową w innym klubie, pomiń ten krok.
          </TextRegular>
        </div>
        <Button variant="outlined" size="small" color="primary" onClick={open}>
          Posiadam licencję
          {currentClub.shortName ? ` w ${currentClub.shortName}` : ''}
        </Button>
      </Box>
      <Dialog open={editMode} onClose={close}>
        <DialogTitle>Licencja sportowa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj rok, w którym uzyskałeś licencję sportową.
          </DialogContentText>
          <TextField onChange={changeInput} value={date} />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Anuluj</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
